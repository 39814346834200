import { Title } from '../components/Title';
import { AltLayout } from '../layouts/AltLayout';
import { Link } from 'gatsby';
import { Seo } from '../components/seo';

const Test: Page = () => (
    <main>
        <Seo
            title="Natural and traditional Medical Care Service in Lafayette, IN - Innovative Medicine "
            description="Innovative Medicine medical team provides Addiction Treatment, Detox Treatment, Diabetes Treatment, Cancer Prevention Treatment and more in Lafayette IN."
        />
        <Title>Hello Test!</Title>
        <p>A starter for Modern Doc Media</p>
        <Link to="/">Home Page</Link>
        <Link to="/bogus">Bogus Link</Link>
    </main>
);

Test.Layout = AltLayout;

export default Test;
